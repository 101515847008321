<template>
  <div class="competence">
    <Header title="政策资讯" index="首页" titleOne="政策管理" beforeTitle="政策资讯" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select
            clearable
            v-model="policyParams.province_id"
            placeholder="请选择省"
            @change="getcity"
          >
            <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select clearable v-model="policyParams.city_id" placeholder="请选择市" @change="gettown">
            <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select clearable v-model="policyParams.town_id" placeholder="请选择区">
            <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select
            clearable
            v-model="policyParams.classify"
            placeholder="请选择政策类型"
            @change="changeType"
          >
            <el-option label="科技政策" value="1">科技政策</el-option>
            <el-option label="公告名单" value="2">公告名单</el-option>
            <el-option label="申报通知" value="3">申报通知</el-option>
            <el-option label="科技项目" value="4">科技项目</el-option>
          </el-select>
          <el-input
            clearable
            v-model="policyParams.keywords"
            placeholder="请输入名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button type="primary" class="policy_add" @click="handleAdd()">新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="policy.data"
        style="width: 100%"
      >
        <el-table-column prop="title" label="政策名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.title ? scope.row.title : "-" }}</template>
        </el-table-column>
        <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
        </el-table-column>
        <el-table-column prop="classify" label="政策类型" :show-overflow-tooltip="true">
          <!-- <template slot-scope="scope">
            {{ scope.row.classify ? scope.row.classify : "-" }}</template
          >-->
          <template slot-scope="scope">
            <span v-if="scope.row.classify == 1">科技政策</span>
            <span v-if="scope.row.classify == 2">科技项目</span>
            <span v-if="scope.row.classify == 3">立项公告</span>
            <span v-if="scope.row.classify == 4">申报通知</span>
          </template>
        </el-table-column>
        <el-table-column prop="effective_time1" label="开始时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.effective_time1 }}</template>
        </el-table-column>
        <el-table-column prop="effective_time2" label="结束时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.effective_time2 }}</template>
        </el-table-column>
        <el-table-column prop="orgazition" label="受理部门" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.organization ? scope.row.organization : "-" }}</template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="230px">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="handleInfo(scope.row)">编辑</el-button>
            <el-button class="edit" size="mini" @click="handleDetail(scope.row.id)">详情</el-button>
            <el-button type="button" class="del" size="mini" @click="handleDel(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="policy.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="policy.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="policy.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="policy.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      policyParams: {
        page: 1,
        limit: 10,
        keywords: "",
        province_id: "",
        city_id: "",
        town_id: "",
        classify: "",
        order: "id desc",
      },
      policy: {},
      province: [],
      city: [],
      town: [],
      options: [],
      value: "",
      input: "",
    };
  },
  activated () {
    // this.policyParams
    this.getList()
  },
  created () {
    this.getprovince();
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      this.axios.get('/api/policy/policy_list', { params: { ...this.policyParams } }).then((res) => {
        this.policy = res.data
        this.loading = false
      })
    },
    handleAdd () {
      this.$router.push("/PoInfo/edit");
    },
    handleSizeChange (val) {
      this.policyParams.limit = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.policyParams.page = val
      this.getList()
    },
    handleSearch () {
      this.getList()
    },
    handleDel (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/api/policy/delete_policy', { params: { id: id } }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleDetail (id) {
      this.$router.push("/PoInfo/detail?id=" + id);
    },
    handleInfo (row) {
      this.$router.push({ path: "/PoInfo/edit", query: { id: row.id } });
    },
    changeType () {
      this.getList()
    },
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
        // this.getList()
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
          this.getList()
        });
      if (this.policyParams.province_id == "0") {
        this.policyParams.city_id = "";
        this.policyParams.town_id = "";
      }
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
          this.getList()
        });
    },
  },
};
</script>

<style scoped="scoped">
.policy_add {
  float: right;
}
</style>
